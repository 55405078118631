import { Link } from "react-router-dom";
import Contact from "../components/Contact";
import CV from '../Veselina Dimova.pdf';
import { scrollToTop } from "../functions/scrollToTop";

export default function About() {
    return (
        <div className="about-me-container">
            <div className="about-me">
                <div className="intro-container">
                    <h1>Hello! My name is Veselina </h1>
                    <p>
                        I was born and raised in Varna, Bulgaria. From an early age, I  discovered the enchanting power of creativity, and it has been an inseparable part of my life ever since.
                    </p>
                    <p>
                        Last July 2022 I graduated Technical university of Varna with bachelor degree in Industrial Design. A few months later I completed courses in UX and UI design.
                    </p>
                    <p>
                        In my free time you’ll find me in the kitchen making pastries, or on the floor of my living room making DIY projects.
                    </p>
                    <p>Resume can be found <Link to={CV} target="_blank">here.</Link></p>
                </div>

                <div className="education-experience-container">
                    <div className="education-container">
                        <div className="education">
                            <h2>Education</h2>

                            <ul >Technical University of Varna
                                <li>Bachelor Degree - Industrial Design</li>
                            </ul>
                            <ul >Soft Uni Creative
                                <li>UX Design</li>
                                <li>UI Design</li>
                            </ul>

                            <h2>Skills</h2>
                            <ul className="skills">
                                <li>Attention to detail</li>
                                <li>Time management</li>
                                <li>Teamwork</li>
                                <li>Problem solving</li>
                                <li>Organisation skills</li>
                                <li>Responsibility</li>
                            </ul>

                            <h2>Computer skills</h2>
                            <ul className="skills">
                                <li>Figma/ Sketch</li>
                                <li>Adobe Illustrator</li>
                                <li>Maze</li>
                                <li>Notion</li>
                                <li>Miro</li>
                                <li>Canva</li>
                                <li>AutoCAD</li>
                                <li>MS Office</li>
                            </ul>

                        </div>
                    </div>
                    <div className="experience-container">
                        <h2>Experience</h2>
                        <ul >Flat Rock Technology
                            <li>Software Support Technician</li>
                            <li>March 2023 - ongoing</li>
                        </ul>
                        <ul >Cherry by Mary
                            <li>Pastry Chef Assistant</li>
                            <li>November 2021 - March 2023</li>
                        </ul>
                        <ul >Sitel
                            <li>Customer Service Representative</li>
                            <li>November 2020 - June 2021</li>
                        </ul>
                    </div>


                </div>
                <Contact />

                <svg onClick={scrollToTop} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
            </svg>
            </div>
        </div>
    )
}


















