import { Link } from 'react-router-dom';

export default function Projects() {
  return (
    <div className="projects-puzzle-container">
      <div className="projects-puzzle-pieces">
        <Link to="/projects/ux-ui" className="projects-left-piece">
          <span>UX/UI</span>
        </Link>
        <Link to="/projects/graphic-design" className="projects-right-piece">
          <span>GRAPHIC DESIGN</span>
        </Link>
      </div>
    </div>
  );
}
