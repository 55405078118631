import { Link } from "react-router-dom"

export default function ContactPage() {

    return (
        <div className="contact-page-container">
            <div className="contact-container">
                <div className="contact-inner">
                    <div className="contact">
                        <div>CONTACT</div>
                        <span>Email: <Link to={"mailto:veselinadimova98@gmail.com"}>veselinadimova98@gmail.com</Link></span>
                        <span>LinkedIn: <Link to={"https://www.linkedin.com/in/v%C3%A9sselina-dimova-35380b26b"} target="_blank">Vésselina Dimova</Link></span>
                        <span>Phone number: +359 878 309 149</span>
                    </div>
                </div>
            </div>
        </div>
    )
}