import './daily-ui.css'

import laptopImage from '../../../images/daily-ui/signup page image.png'
import cardCheckoutImage from '../../../images/daily-ui/card checkout 1.png'
import calculatorImage from '../../../images/daily-ui/calculator 1.png'



export default function DailyUI() {
    return (
        <div className="daily-ui-container">
            <div className="sign-up-page">
                <img src={laptopImage} />
                <h3>Sign Up Page</h3>
            </div>
            <div className="daily-ui-phones">
                <div className="card-checkout">
                    <img src={cardCheckoutImage} />
                    <h3>Credit Card Checkout</h3>
                </div>
                <div className="calculator">
                    <img src={calculatorImage} />
                    <h3>Calculator</h3>
                </div>
            </div>
        </div>
    )
}