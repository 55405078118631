export default function GPInfoInner() {
    return (
        <div className="grocery-pal-info-inner">
            <h1>Grocery Pal</h1>
            <p>An end-to-end project from concept
                ideation to interaction and prototyping.
            </p>

            <div className="grocery-pal-details-section">
                <div id="project-overview">
                    <h3>Project overview</h3>
                    <p>
                        Grocery Pal is a solution for users who tend to
                        throw away food, because they forget that they bought it.
                    </p>
                </div>
                <div>
                    <h3>Details</h3>
                    <p>
                        Tools: Figma, FigJam, Maze
                    </p>
                </div>
            </div>
            <h1>Research</h1>

            <h3>Questionnaire</h3>
            <ol>
                <li> What is your age?</li>
                <li> What is your gender?</li>
                <li>What do you do for a living?</li>
                <li>How ofter do you grocery shop?</li>
                <li>What kind of groceries do you shop? Ex. Vegetables, fresh meat etc.</li>
                <li>How many members does your household have?</li>
                <li>Do you make a grocery shopping list in advance?</li>
                <li>Do you tend to buy a lot of groceries at once?</li>
                <li>Do you throw most of the food in your fridge, because it expires before you cook it?</li>
                <li>What do you think is the reason your food is stagnating in your fridge?</li>
            </ol>

            <h3>Persona</h3>
        </div>
    )
}