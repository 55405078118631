import womanImage from '../../../images/grocery-pal/womanImage.png'


export default function GPPersona() {
    return (
        <div className="persona-container">
            <div className="persona-person-container">
                <div className="person-container">
                    <div className="person">
                        <div className="person-name-image-container">
                            <h4 id="person-name">Aneliya</h4>
                            <img className="person-image" src={womanImage} />
                        </div>
                        <div className="person-description-container">
                            <div className="person-info-description">
                                <span>Age</span>
                                <span>Edication</span>
                                <span>Job</span>
                                <span>Location</span>
                                <span>Computer skills level</span>
                            </div>
                            <div className="person-info-description" id="person-info">
                                <span>37</span>
                                <span>Industrial Management</span>
                                <span>Engineer</span>
                                <span>Varna</span>
                                <span>High</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="person-description">
                    <p>“I am tired of regularly throwing away a lot of food because I tend to buy it and forget about it being in my fridge to only notice that once it has expired.”</p>
                </div>

                <div className="personality-container">
                    <h4>Personality</h4>
                    <span id="personality">Extrovert</span>
                    <span id="personality">Impulsive</span>
                    <span id="personality">Emotional</span>
                </div>
            </div>

            <div className="persona-detail-description-container">
                <div className="persona-biography-container">
                    <h4>Biography</h4>
                    <p>Aneliya is born and living in Varna. She’s married and has a daughter who is 3 years old. Aneliya shops groceries every 2 -3 days and throws away food, because she forgets that she bought it, or because she doesn’t have time to cook it.</p>
                </div>
                <div className="persona-needs-container">
                    <h4>Needs</h4>
                    <ul className="persona-needs-list">
                        <li>Aneliya needs to shop in smaller quantities</li>
                        <li>She needs to make a shopping list in advance</li>
                        <li>She needs to stop wasting food</li>
                    </ul>
                </div>
                <div className="persona-frustrations-container">
                    <h4>Frustrations</h4>
                    <ul className="persona-frustrations-list">
                        <li>Aneliya is throwing money down the drain when she buys food and throws it away after it expires.</li>
                        <li>Often doesn’t have ideas how to prepare the groceries.</li>
                        <li>She needs to stop wasting food</li>
                        <li>Doesn’t use the whole product after opening it.</li>
                        <li>Cooks a few meals at once and they don’t get eaten.</li>
                    </ul>
                </div>
                <div className="persona-methods-container">
                    <div className="persona-methods">
                        <h4>Paying methods</h4>
                        <span className="persona-method">Cash</span>
                        <span className="persona-method">Card</span>
                    </div>
                    <div className="persona-methods">
                        <h4>Communication</h4>
                        <span className="persona-method">Phone</span>
                        <span className="persona-method">Laptop</span>
                    </div>
                </div>
            </div>
        </div>
    )
}