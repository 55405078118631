import { Route, Routes } from 'react-router-dom';
import Projects from '../pages/Projects';
import ProjectsGD from '../pages/ProjectsGD';
import ProjectsUi from '../pages/ProjectsUI';
import DailyUI from '../components/projects components/DailyUI/DailyUI';
import GroceryPal from '../components/projects components/GroceryPal/GroceryPal';
import PersonalProjects from '../components/projects components/GraphicDesign/PersonalProjects/PersonalProjects';
import Home from '../pages/Home';
import About from '../pages/About';
import ContactPage from '../pages/ContactPage';



export default function RoutesComponent() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path="/projects">
                <Route index element={<Projects />} />
                <Route path="ux-ui">
                    <Route index element={<ProjectsUi />} />
                    <Route path="grocery-pal" element={<GroceryPal />} />
                    <Route path="daily-ui" element={<DailyUI />} />
                </Route>
                <Route path="graphic-design" element={<PersonalProjects />} />
            </Route>
        </Routes>
    )
}