import homeImage from '../home-image.jpeg';
import homeImageBackground from '../image-puzzle.png';


export default function Home() {
    return (
        <div className="home-container">
            <div className="home-intro">
                <h1>Hey there!</h1>
                <p>I’m an engineer - designer graduate from Varna who has a passion for graphic design and is recently very interested in UX/UI design and is always eager to learn.
                </p>
            </div>
            <div className="home-image-container">
                <img className="home-image-background" src={homeImageBackground} />
                <img className="home-image" src={homeImage} />
            </div>
        </div>
    )
}
