import { Link } from 'react-router-dom';

export default function Navigation() {
    return (
        <nav className="navbar fixed navbar-expand-lg">
            <div className="container-fluid justify-content-evenly">
                <div className="flex-grow-0">
                    <div className="logo-left-piece"></div>
                    <div className="logo-right-piece"></div>
                    <Link className="nav-link" to="/">VESSY</Link>

                </div>

                <div className="collapse navbar-collapse justify-content-center flex-grow-1 collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">HOME</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">ABOUT ME</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/projects">PROJECTS</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">CONTACT</Link>
                        </li>

                    </ul>
                </div>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
               
            </div>
        </nav>
    )


}