import './main.css';
import Navigation from './pages/Navigation';
import RoutesComponent from './routes/Routes';

function App() {
  return (
    <div className="App">
      <Navigation />
      <main>
        <RoutesComponent />
      </main>
    </div>
  );
}

export default App;
