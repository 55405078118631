import userJourney from '../../../images/grocery-pal/user-journey.png'
import positiveStoryboard from '../../../images/grocery-pal/positive-storyboard.png'
import negativeStoryboard from '../../../images/grocery-pal/negative-storyboard.png'
import designImage from '../../../images/grocery-pal/grocery-pal-design.png'
import moodboardImage from '../../../images/grocery-pal/moodboard grocery pal 1.png'
import wireframesImage from '../../../images/grocery-pal/grocery-pal-wireframes.png'
import designSystem from '../../../images/grocery-pal/design system 1.png'


export default function GPUserStory() {
    return (
        <>
            <div className="grocery-pal-user-story">
                <h3>User Story</h3>
                <ul>
                    <li>As Aneliya, I would like to stop wasting my money by throwing food away.</li>
                    <li>As Aneliya, I would like to use the whole product once it is opened.</li>
                    <li>As Aneliya, I would like to find recipes based on what I have in my fridge.</li>
                    <li>As Aneliya, I would like to find recipes based on the number of people in my household.</li>
                </ul>
            </div>
            <div className="grocery-pal-user-journey">
                <h3>User Journey</h3>
                <div>
                    <img src={userJourney} />
                </div>
            </div>
            <div className="grocery-pal-storyboard">
                <h3>Positive Storyboard</h3>
                <img src={positiveStoryboard} />
                <h3>Negative Storyboard</h3>
                <img src={negativeStoryboard} />

                <h1>Design</h1>
                <h3 id="low-fidelity-wireframe">Low - fidelity wireframe</h3>
                <img src={designImage} className="grocery-pal-design-image" />
                <h3>Moodboard</h3>
                <img className="grocery-pal-moodboard" src={moodboardImage} />
                <h3>Wireframes</h3>
                <img className="grocery-pal-wireframes" src={wireframesImage} />
                <h3>Design System</h3>
                <img className="grocery-pal-design-system" src={designSystem} />
            </div>
        </>
    )
}