import './grocery-pal.css'
import phoneImage1 from '../../../images/grocery-pal/1 1.png'
import phoneImage2 from '../../../images/grocery-pal/2 1.png'
import phoneImage3 from '../../../images/grocery-pal/3 1.png'

import GPInfoInner from './GPInfoInner'
import GPPersona from './GPPersona'
import GPUserStory from './GPUserStory'
import GPUsability from './GPUsability'
import Contact from '../../../components/Contact'
import { scrollToTop } from '../../../functions/scrollToTop'

export default function GroceryPal() {

    

    return (
        <div className="grocery-pal">
            <div className="grocery-pal-container">
                <div className="grocery-pal-images">
                    <img className="grocery-pal-image" src={phoneImage1} />
                    <img className="grocery-pal-image" src={phoneImage2} />
                    <img className="grocery-pal-image" src={phoneImage3} />
                </div>
                <div className="grocery-pal-info-container">
                    <GPInfoInner />
                    <GPPersona />
                    <div className="grocery-pal-user-story-container">
                        <GPUserStory />
                        <GPUsability />
                        <Contact />

                    </div>
                </div>
            </div >

            <svg onClick={scrollToTop} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
            </svg>
        </div>
    )
}