import finalImage1 from '../../../images/grocery-pal/final-1.png'
import finalImage2 from '../../../images/grocery-pal/final-2.png'
import finalImage3 from '../../../images/grocery-pal/final-3.png'
import finalImage4 from '../../../images/grocery-pal/final-4.png'
import finalImage5 from '../../../images/grocery-pal/final-5.png'
import finalImage6 from '../../../images/grocery-pal/final-6.png'


export default function GPUsability() {
    return (
        <div className="grocery-pal-usability-container">
            <h3 id="usability-testing">Usability testing results</h3>
            <h4>Test Goals</h4>
            <ul>
                <li>To observe users’ interactions with the app and evaluate their experiences with the key features.</li>
                <li>To identify potential pain points. </li>
                <li>To define the quality of accessibility, flow, navigation, information architecture, and general design of the addition.</li>
            </ul>
            <ul id="usability-list">
                <li>7 of 11 people completed the test.</li>
                <li>22% of them often throw food away</li>
                <li>22% sometimes</li>
                <li>44% very rarely</li>
            </ul>

            <img src={finalImage1} />
            <img src={finalImage2} />
            <img src={finalImage3} />
            <img src={finalImage4} />
            <img src={finalImage5} />

            <h3>Conclusions</h3>
            <img src={finalImage6} />

        </div>
    )
}