import './personal-projects.css';

import jamCollageOrange from '../../../../images/personal-projects/jam collage2 1.png'
import jamCollageRed from '../../../../images/personal-projects/jam collage3 1.png'
import jamCollageOPurple from '../../../../images/personal-projects/jam collage 4.png'
import businessCard from '../../../../images/personal-projects/business card2 1.png'
import bookCover from '../../../../images/personal-projects/book-cover.png'
import cakeCard from '../../../../images/personal-projects/cake-card.png'
import lemonsImage from '../../../../images/personal-projects/lemons.png'


import Contact from '../../../Contact'
import { scrollToTop } from '../../../../functions/scrollToTop';

export default function PersonalProjects() {
    return (
        <div className="personal-projects-container">
            <div className="jams-container">
                <h1>Jam Labels</h1>
                <img className="jam" src={jamCollageOrange} />
                <img className="jam" src={jamCollageRed} />
                <img className="jam" src={jamCollageOPurple} />
            </div>
            <div className="business-card-container">
                <h1>Business Card</h1>
                <img className="business-card" src={businessCard} />
            </div>
            <div className="image-poster-container">
                <h1>Book Cover</h1>
                <img className="image-poster" src={bookCover} />
            </div>

            <div className="image-poster-container">
                <h1>Card “How to store a cake properly”</h1>
                <img className="image-poster" src={cakeCard} />
            </div>

            <div className="image-poster-container">
                <h1>Lemons Illustration</h1>
                <img className="image-poster" src={lemonsImage} />
            </div>

            <Contact />

            <svg onClick={scrollToTop} xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
            </svg>
        </div>
    )
}