import { Link } from 'react-router-dom'


export default function Contact() {
    return (
        <div className="contact-container">
            <div className="contact-inner">
                <div className="logo-contact">
                    <div className="logo-left-piece"></div>
                    <div className="logo-right-piece"></div>
                    <Link className="nav-link" to="/">VESSY</Link>
                </div>
                <div className="contact">
                    <div>CONTACT</div>
                    <span>Email: <Link to={"mailto:veselinadimova98@gmail.com"}>veselinadimova98@gmail.com</Link></span>
                    <span>LinkedIn: <Link to={"https://www.linkedin.com/in/v%C3%A9sselina-dimova-35380b26b"} target="_blank">Vésselina Dimova</Link></span>
                    <span>Phone number: +359 878 309 149</span>
                </div>
            </div>
        </div>
    )
}