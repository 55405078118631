import { Link } from 'react-router-dom';

export default function ProjectsUi() {
    return (
        <div className="projects-puzzle-container">
            <div className="projects-puzzle-pieces">
                    <Link to="/projects/ux-ui/grocery-pal" className="projects-left-piece">
                        <span>GROCERY PAL</span>
                    </Link>
                    <Link to="/projects/ux-ui/daily-ui" className="projects-right-piece">
                        <span>DAILY UI</span>
                    </Link>
            </div>
        </div>
    )
}